.popup{
    height: 400px;
    width: 70%;
    background-color: rgb(233, 216, 216);
    position: absolute;
    top: 3%;
    right: 25%;
}

.popup-header{
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 15px;
    border-bottom: 2px solid black;

}

.main{
   
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

}
.cut{
    cursor: pointer;

}

.container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}




.modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
}


.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-x: scroll;
}


.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    border: 1px solid;
}

.container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 110px;      
}