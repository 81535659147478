
.tabs {
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
}

.tab {
    height: 0;
    width: 150px;
    border-bottom: 35px solid #CCCCCC;
    border-right: 25px solid transparent;
    box-sizing: border-box;
    background: white;
    font-size: 14px;
    outline: none;
    line-height: 2.5;
    border-top: none;
    border-left: none;
    font-size: 13px;
    font-weight: 600;
}

.tab:not(:first-child) {
    z-index: 0;
}

.tab .label {
    padding: 8px;
    text-align: center;
    color: #444444;
    font-weight: 600;
}

.active {
    border-bottom: 35px solid rgb(220 40 40);
    color: white;
    line-height: 2.5;
}

.active .label {
    color: #ffffff;
}